import restApi from '@/utils/rest-api';

import {
  muPersonAvatar,
  muPersonAvatarLoading,
  muPersonAvatarError
} from './mutation-types';

const storeState = {
  loading: false,
  binaryAvatar: null,
  errorMessage: null
};

const getters = {
  personAvatarLoading: state => state.loading,
  personAvatar: state => state.binaryAvatar
    ? `data:image/png;base64,${state.binaryAvatar.toString('base64')}`
    : null,
  personAvatarErrorMessage: state => state.errorMessage
};

const actions = {
  async fetchPersonAvatar({ commit, state }) {
    if (state.binaryAvatar) {
       return;
    }

    commit(muPersonAvatarLoading);

    try {
      const url = '/api/v1/PersonAvatar';
      const response = await restApi.get(url, { responseType: 'arraybuffer' });

      commit(muPersonAvatar, response.data);
    } catch (e) {
      commit(muPersonAvatarError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muPersonAvatarLoading](state) {
    state.loading = true;
    state.errorMessage = '';
  },

  [muPersonAvatar](state, data) {
    state.loading = false;
    state.binaryAvatar = Buffer.from(data, 'binary');
  },

  [muPersonAvatarError](state, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const personAvatar = {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations
};
