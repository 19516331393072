import Vue from 'vue';
import VueRouter from 'vue-router';
import AppLayout from "@/layouts/App.vue";
import StatisticsLayout from "@/layouts/Statistics.vue";
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import OidcCallback from '@/views/auth/OidcCallback';
import OidcCallbackError from '@/views/auth/OidcCallbackError';
import store from '@/store';
import { Role } from './role';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/CoachCertificate',
    meta: { authorize: []},
    component: AppLayout,
    children: [
      {
        path: '/CoachCertificate',
        name: 'CoachCertificate',
        // route level code-splitting
        // this generates a separate chunk (coachCertificatePage.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "coachCertificatePage" */ '../views/coach/CoachCertificate.vue'),
        meta: { breadCrumb: 'coachCertificate.header', authorize: []}
      },
      {
        path: '/NoAccess',
        name: 'NoAccess',
        // route level code-splitting
        // this generates a separate chunk (coachCertificatePage.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "coachCertificatePage" */ '../views/common/components/NoAccess.vue'),
        meta: { authorize: []}
      },
      {
        path: '/OrgSearch',
        name: 'OrgSearch',
        // route level code-splitting
        // this generates a separate chunk (orgSearchPage.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "orgSearchPage" */ '../views/coach/OrgSearch.vue'),
        meta: { breadCrumb: 'newCoachCertificate.buttonSearch', authorize: []}
      },
      {
        path: '/NewCoachCertificate',
        name: 'NewCoachCertificate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newCoachCertificatePage" */ '../views/coach/NewCoachCertificate.vue'),
        meta: { breadCrumb: 'newCoachCertificate.buttonMembership', authorize: []}
      },
      {
        path: '/ConfirmCoachCertificate',
        name: 'ConfirmCoachCertificate',
        // route level code-splitting
        // this generates a separate chunk (confirmCoachCertificatePage.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "confirmCoachCertificatePage" */ '../views/coach/ConfirmCoachCertificate.vue'),
        meta: { breadCrumb: 'confirmCoachCertificate.subheader', authorize: []}
      },
      {
        path: '/SelectCoachTypeWhitoutOrg',
        name: 'SelectCoachTypeWhitoutOrg',
        // route level code-splitting
        // this generates a separate chunk (selectCoachTypeWhitoutOrgPage.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "selectCoachTypeWhitoutOrgPage" */ '../views/coach/SelectCoachTypeWhitoutOrg.vue'),
        meta: { breadCrumb: 'newCoachCertificate.buttonNoAffiliation', authorize: []}
      },
      {
        path: '/About',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/common/components/AboutPage.vue'),
        meta: { breadCrumb: 'aboutHeader', authorize: []}
      }
    ]
  },
  {
    path: "/Chart",
    redirect: '/Chart',
    component: StatisticsLayout,
    children: [
      {
        path: '/Chart',
        name: 'Chart',
        // route level code-splitting
        // this generates a separate chunk (chartPanel.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chartPanel" */ '../views/admin/ChartPanel.vue'),
        meta: { authorize: [Role.SuperAdmin], orgtypes:[]}
      }
    ]
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-callback-error',
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to) {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
  routes
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const isAuthenticated = store.getters['oidcStore/oidcIsAuthenticated'];
  if (authorize) {
      if (!isAuthenticated) {
          next();
      }
      const currentUser = store.getters['oidcStore/oidcUser'];
      const functionTypes =  currentUser.functiontypeids.split(',').map(x => Number(x));
      const functionTypeIds = [...new Set(functionTypes)];
      const authorizeIds = [...new Set(authorize[0])];

      const intersection = authorizeIds.filter(element => functionTypeIds.includes(element));
      // check if route is restricted by role
      if (authorize.length && intersection.length <= 0) {
          // role not authorised so redirect to NoAccess page
          return next({ path: '/NoAccess' });
      }
   }
  next();
});

export default router;
