// context org
export const muSetContextOrgId = 'muSetContextOrgId';

// orgs
export const muSetOrgs = 'muSetOrgs';
export const muSetClubs = 'muSetClubs';
export const muSetBranches = 'muSetBranches';
export const muSetOrgsLoading = 'muSetOrgsLoading';
export const muSetOrgsError = 'muSetOrgsError';

// person avatar
export const muPersonAvatar = 'muPersonAvatar';
export const muPersonAvatarLoading = 'muPersonAvatarLoading';
export const muPersonAvatarError = 'muPersonAvatarError';

// memberships
export const muSetMemberships = 'muSetmuSetMembershipsOrgs';
export const muSetMembershipsLoading = 'muSetMembershipsLoading';
export const muSetMembershipsError = 'muSetMembershipsError';
export const muSetMembershipRelations = 'muSetMembershipRelations';
export const muSetSelected = 'muSetSelected';
export const muDeleteSelected = 'muDeleteSelected';
export const muSetSelectedOrgs = 'muSetSelectedOrgs';


// coachcertificates
export const muSetCoachCertifiate = 'muSetCoachCertifiate';
export const muSetCoachCertifiatesByPersonId = 'muSetCoachCertifiatesByPersonId';
export const muSetCoachCertificateStatistics = 'muSetCoachCertificateStatistics';
export const muSetCoachcertificatesLoading = 'muSetCoachcertificatesLoading';
export const muSetCoachcertificatesError = 'muSetCoachcertificatesError';
export const muSetSaveCoachcertificatesError = 'muSetSaveCoachcertificatesError';
export const muSetDeletedCoachCertifiatesByOrgId = "muSetDeletedCoachCertifiatesByOrgId";
export const muSetPassiveGroup = 'muSetPassiveGroup';
export const muSetCoachCertificateDeletedGroup= 'muSetCoachCertificateDeletedGroup';
export const muSetCoachCertificateGroup= 'muSetCoachCertificateGroup';

//competences
export const muSetCompetencesByPersonId = 'muSetCompetencesByPersonId';
export const muSetCompetencesError = 'muSetCompetencesError';
export const muSetCompetencesLoading = 'muSetCompetencesLoading';

//snackbar
export const muSetSnackbarActive = "muSetSnackbarActive";
export const muSetSnackbarTimeout = "muSetSnackbarTimeout";
export const muSetSnackbarText = "muSetSnackbarText";


