<template>
  <v-card class="mx-auto mt-2" elevation="0" width="350" max-height="650" @click="$emit('click')">
    <div class="primary--text white">
      <Localization />
      <div class="text-center pb-5 mb-10">
        <v-btn depressed rounded outlined large width="230" @click="logOut"
          class="v-btn--accent-border text-none">
          {{ $t('commonStrings.signOut') }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Localization from '@/views/common/components/Localization.vue';

export default {
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapGetters('oidcStore', [
      'oidcIsAuthenticated'
    ]),
    ...mapGetters('claims', [
      'personId',
      'contextOrgId',
      'orgContexts'
    ]),
    ...mapGetters('orgs', [
      'orgs'
    ]),
    filteredOrgContexts() {
      return this.orgs;
    },
    contextOrgIdKey() {
      return `contextOrgId_${this.personId}`;
    },
    index: {
      set(index) {
        if (typeof index !== 'undefined') {
          const { orgId } = this.filteredOrgContexts[index];
          this.setContextOrgId(orgId);
          this.$cookies.set(this.contextOrgIdKey, orgId);
        }
      },
      get() {
        return this.contextOrgId
          ? this.filteredOrgContexts.findIndex(x => x.orgId == this.contextOrgId)
          : 0;
      }
    }
  },
  methods: {
    ...mapActions('oidcStore', [
      'signOutOidc'
    ]),
    ...mapActions('claims', [
      'setContextOrgId'
    ]),
    logOut(){
      this.$cookies.remove(this.contextOrgIdKey);
      this.signOutOidc();
    }
  },
  components: {
    Localization
  }
};
</script>
