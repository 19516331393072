import {
    muSetCompetencesByPersonId,
    muSetCompetencesError,
    muSetCompetencesLoading
} from './mutation-types';

  import api from '@/utils/api';

  const state = {
    competencesByPersonId: [],
    loading: false
  };

  const getters = {
    competencesByPersonId: () => state.competencesByPersonId,
    loading: () => state.loading
  };

  const actions = {
    async fetchCompetencesByPersonId({ commit }, id) {
      commit(muSetCompetencesLoading);

      try {
        if (id <= 0) {
          commit(muSetCompetencesByPersonId, []);
          return;
        }
        const data = { id: Number(id) };
        const response = await api.post('./api/v1/Competence/GetCoachCertificateCompetencesByPersonId', data);
        commit(muSetCompetencesByPersonId, response.data);
      } catch (e) {
        commit(muSetCompetencesError, { errorMessage: e.message });
      }
    }
  };

  const mutations = {
    [muSetCompetencesLoading]() {
      state.loading = true;
      state.errorMessage = '';
    },

    [muSetCompetencesByPersonId](_,items) {
        state.loading = false;
        state.competencesByPersonId = items;
    },

    [muSetCompetencesError](_, { errorMessage }) {
      state.loading = false;
      state.errorMessage = errorMessage;
    }
  };

  export const competences = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
