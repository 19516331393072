import {
    muSetSnackbarActive,
    muSetSnackbarTimeout,
    muSetSnackbarText
  } from './mutation-types';

  const state = {
    snackbarText: '',
    timeout: 4000,
    isActive: false
  };

  const getters = {
    timeout:() => state.timeout,
    isActive: () => state.isActive,
    snackbarText: () => state.snackbarText
  };

  const actions = {
    setActive({ commit }, item) {
        commit(muSetSnackbarActive,  item);
      },

    setTimeout({ commit }, item) {
        commit(muSetSnackbarTimeout,  item);
    },

    setSnackbarText({ commit }, item) {
        commit(muSetSnackbarText,  item);
    }
  };

  const mutations = {
    [muSetSnackbarActive](_, item) {
        state.isActive = item;
      },

    [muSetSnackbarTimeout](_, item) {
      state.timeout = item;
    },

    [muSetSnackbarText](_, item) {
        state.snackbarText = item;
    }
  };

  export const snackbar = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
