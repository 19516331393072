import {
    muSetCoachCertifiate,
    muSetCoachCertifiatesByPersonId,
    muSetCoachCertificateStatistics,
    muSetCoachcertificatesLoading,
    muSetCoachcertificatesError,
    muSetSaveCoachcertificatesError
} from './mutation-types';

import api from '@/utils/api';
import CoachCertificateStatus from '@/enums/CoachCertificateStatus';

const state = {
    errorMessage: null,
    coachCertificate: null,
    certifiatesByPersonId:[],
    certificateStatistics:[],
    loading: false
};

const getters = {
    errorMessage: () => state.errorMessage,
    editedItem: () => {
      return {
        "coachCertificateId": 0,
        "personId": 0,
        "orgId": 0,
        "coachTypeId": 1,
        "statusId": CoachCertificateStatus.NotStarted,
        "isApproved": false
      };
    },
    coachCertificate: () => state.coachCertificate,
    certifiatesByPersonId: () => state.certifiatesByPersonId,
    certificateStatistics: () => state.certificateStatistics,
    hasCertificates: () => state.certifiatesByPersonId.length > 0,
    loading: () => state.loading
};

const actions = {
    async fetchCoachCertifiateByPersonId({ commit }, id) {
      commit(muSetCoachcertificatesLoading);

      try {
        if (id <= 0) {
          commit(muSetCoachCertifiatesByPersonId, []);
          return;
        }
        const data = { id: Number(id) };
        const response = await api.post('./api/v1/CoachCertificate/GetByPersonId', data);

        commit(muSetCoachCertifiatesByPersonId, response.data);
      } catch (e) {
        commit(muSetCoachcertificatesError, { errorMessage: e.message });
      }
    },

    async fetchCoachCertificateStatistics({ commit }, id) {
      commit(muSetCoachcertificatesLoading);

      try {
        if (id <= 0) {
          commit(muSetCoachCertificateStatistics, []);
          return;
        }
        const data = { id: Number(id) };
        const response = await api.post('./api/v1/CoachCertificate/GetCoachCertificateStatistics', data);

        commit(muSetCoachCertificateStatistics, response.data);
      } catch (e) {
        commit(muSetCoachcertificatesError, { errorMessage: e.message });
      }
    },

    async saveCoachCertifiate({ commit }, editedItem) {
      commit(muSetCoachcertificatesLoading);

      try {
        if (editedItem.personId <= 0) {
          commit(muSetCoachcertificatesError, { errorMessage: 'PersonId must have a value.' });
          return;
        }

      const data = {
        "coachCertificateId": 0,
        "personId": editedItem.personId,
        "orgId": editedItem.orgId,
        "coachTypeId": editedItem.coachTypeId,
        "statusId": editedItem.statusId,
        "isApproved": editedItem.isApproved
      };

      const response = await api.post('./api/v1/CoachCertificate', data);

      commit(muSetCoachCertifiate, response.data);
      } catch (e) {
        if (e.response.data.errors) {
          commit(muSetSaveCoachcertificatesError, e.response.data.errors);
        } else {
          commit(muSetCoachcertificatesError, { errorMessage: e.message });
        }
      }
    }
};

const mutations = {
    [muSetCoachcertificatesLoading]() {
      state.loading = true;
      state.errorMessage = '';
    },
    [muSetCoachCertifiate](_,item) {
      state.loading = false;
      state.coachCertificate = item;
    },
    [muSetCoachCertifiatesByPersonId](_,items) {
      state.loading = false;
      state.certifiatesByPersonId = items;
    },

    [muSetCoachCertificateStatistics](_,items) {
      state.loading = false;
      state.certificateStatistics = items;
    },

    [muSetCoachcertificatesError](_, { errorMessage }) {
      state.loading = false;
      state.errorMessage = errorMessage;
    },
    [muSetSaveCoachcertificatesError](_, errorMessages) {
      state.loading = false;
      state.errorMessages = errorMessages;
    }
};

export const coachcertificates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};