export const Role = {
    Admin: [
        //Leder
        1,
        //Nestleder
        2,
        //Daglig leder
        15,
        //Adm.leder (ansatt)
        24,
        //Ansvarlig politiattest
        202401,
        //Medlemsansvarlig
        40000004,
        //Org.ansvarlig KL
        40000008,
        // Administrativt ansatt SF/SK
        40000005,
        //Org. administrator SF/SK
        40000002,
        //Superadministrator Sportsadmin
        50000015
    ],
    PoliceCertificateAdmin:[
        //Ansvarlig politiattest
        202401
    ],
    SuperAdmin: [
        //Superadministrator Sportsadmin
        50000015
    ]
};

export default Role;