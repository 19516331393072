import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(config => {
  if (store.getters['oidcStore/oidcIsAuthenticated']) {
    const token = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`;
    const contextOrgId = store.getters['claims/contextOrgId'];

    config.headers['Authorization'] = token;
    config.headers['nif-org-id'] = contextOrgId;
  }

  return config;
}, error => Promise.reject(error));


const api = {
  get(resource) {
    return axios.get(resource);
  },
  post(resource, data) {
    return axios.post(resource, data);
  },
  put(resource, data) {
    return axios.put(resource, data);
  },
  delete(resource) {
    return axios.delete(resource);
  }
};

export default api;