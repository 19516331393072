import {
  muSetOrgs,
  muSetClubs,
  muSetBranches,
  muSetOrgsLoading,
  muSetOrgsError
} from './mutation-types';

import api from '@/utils/api';

const state = {
  orgs: [],
  clubs:[],
  branches:[],
  loading: false
};

const getters = {
  orgs: () => state.orgs,
  getOrgName: state => id => {
    const org = state.orgs.find(x => x.orgId === id);
    return org?.describingName;
  },
  clubs:() => state.clubs,
  branches:() => state.branches,
  loading: () => state.loading
};

const actions = {
  async fetchOrgs({ commit }, orgIds) {
    commit(muSetOrgsLoading);

    try {
      if (!orgIds.length) {
        commit(muSetOrgs, []);

        return;
      }

      const data = { orgIds };

      const response = await api.post('./api/v1/Org/GetByIds', data);

      commit(muSetOrgs, response.data);
    } catch (e) {
      commit(muSetOrgsError, { errorMessage: e.message });
    }
  },

  async fetchClubs({ commit }, orgName) {
    commit(muSetOrgsLoading);

    try {
      const data = { orgName };

      const response = await api.post('./api/v1/Org/GetClubs', data);

      commit(muSetClubs, response.data);
    } catch (e) {
      commit(muSetOrgsError, { errorMessage: e.message });
    }
  },

  async fetchBranches({ commit }, orgIds) {
    commit(muSetOrgsLoading);

    try {
      if (!orgIds.length) {
        commit(muSetBranches, []);

        return;
      }
      const data = { orgIds };

      const response = await api.post('./api/v1/Org/GetBranches', data);

      commit(muSetBranches, response.data);
    } catch (e) {
      commit(muSetOrgsError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muSetOrgsLoading]() {
    state.loading = true;
    state.errorMessage = '';
  },

  [muSetOrgs](_,items) {
     state.loading = false;
     state.orgs = items;
  },

  [muSetClubs](_,items) {
    state.loading = false;
    state.clubs = items;
  },

  [muSetBranches](_,items) {
    state.loading = false;
    state.branches = items;
  },

  [muSetOrgsError](_, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const orgs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
