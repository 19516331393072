import {
  muSetMemberships,
  muSetMembershipRelations,
  muSetMembershipsLoading,
  muSetMembershipsError,
  muSetSelected,
  muDeleteSelected,
  muSetSelectedOrgs
} from './mutation-types';

import api from '@/utils/api';

const state = {
  policeCertificateResponsible: null,
  memberships: [],
  relations: [],
  selections: [],
  selectedOrgs:[],
  loading: false
};

const getters = {
  memberships: () => state.memberships,
  relations:() => state.relations,
  loading: () => state.loading,
  selections: () => state.selections,
  selectedOrgs: () => state.selectedOrgs,
  policeCertificateResponsible: () => state.policeCertificateResponsible
};

const actions = {
  async fetchMemberships({ commit }, personId) {
    commit(muSetMembershipsLoading);

    try {
      if (personId <= 0) {
        commit(muSetMemberships, []);
        return;
      }
      const response = await api.get('./api/v1/Membership?id=' + personId);

      commit(muSetMemberships, response.data);
    } catch (e) {
      commit(muSetMembershipsError, { errorMessage: e.message });
    }
  },
  async fetchRelations({ commit }, personId) {
    commit(muSetMembershipsLoading);

    try {
      if (personId <= 0) {
        commit(muSetMembershipRelations, []);
        return;
      }
      const response = await api.get('./api/v1/Membership/GetRelations?id=' + personId);

      commit(muSetMembershipRelations, response.data);
    } catch (e) {
      commit(muSetMembershipsError, { errorMessage: e.message });
    }
  },

  async fetchSelectedOrgs({ commit }, personId) {
    commit(muSetMembershipsLoading);

    try {
      if (personId <= 0) {
        commit(muSetSelectedOrgs, []);
        return;
      }
      const orgIds = state.selections;
      const id = Number(personId);
      const data = { orgIds: orgIds,  personId: id};
      const response = await api.post('./api/v1/CoachCertificate/GetSelectedOrgs', data);

      commit(muSetSelectedOrgs, response.data);
    } catch (e) {
      commit(muSetMembershipsError, { errorMessage: e.message });
    }
  },

  setSelected({ commit, dispatch }, {items, personId}) {
    commit(muSetSelected,  items);

    dispatch('fetchSelectedOrgs', personId);
  },

  deleteSelected({ commit, dispatch }, {item, personId} ) {
    commit(muDeleteSelected,  item );

    dispatch('fetchSelectedOrgs',  personId);
  }
};

const mutations = {
  [muSetMembershipsLoading]() {
    state.loading = true;
    state.errorMessage = '';
  },

  [muSetMemberships](_,items) {
      state.loading = false;
      state.memberships = items;
  },

  [muSetMembershipRelations](_,items) {
    state.loading = false;
    state.relations = items;
  },

  [muSetSelectedOrgs](_,items) {
    state.loading = false;
    state.selectedOrgs = items;
  },

  [muSetSelected](_, items) {
    for (const i in items) {
      if (items.length > 0) {
        const index = state.selections.findIndex(item => item === items[i]);
        if(index === -1){
          state.selections.push(items[i]);
        }
      }
    }
  },

  [muDeleteSelected](_, item) {
    const index = state.selections.findIndex(selected => selected === item);
    if(index !== -1){
      state.selections.splice(index, 1);
    }
  },

  [muSetMembershipsError](_, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const memberships = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
