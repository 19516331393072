<template>
<div id="appLayout">
  <v-app>
    <page-header />
    <v-main>
      <router-view></router-view>
      <div v-if="!mobile" class="background-image">
        <img src="@/assets/Trenerattest-haand-holder-mobil.png"
          :alt="$t('homeIllustration')" />
      </div>
      <page-footer />
    </v-main>
  </v-app>
</div>
</template>

<script>
import PageHeader from '@/views/common/components/PageHeader.vue';
import PageFooter from '@/views/common/components/PageFooter.vue';

export default {
  name: 'AppLayout',
  components: {
    PageHeader,
    PageFooter
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>