import Vue from 'vue';
import Vuex from 'vuex';

import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '@/config/oidc';
import { claims } from './modules/claims';
import { orgs } from './modules/orgs';
import { coachcertificates } from './modules/coachcertificates';
import { personAvatar } from './modules/personAvatar';
import { memberships } from './modules/memberships';
import { competences } from './modules/competences';
import { snackbar } from './modules/snackbar';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const oidcStore = vuexOidcCreateStoreModule(oidcSettings, { namespaced: true });

export default new Vuex.Store({
  modules: {
    oidcStore,
    claims,
    orgs,
    personAvatar,
    memberships,
    coachcertificates,
    competences,
    snackbar
  },
  strict: debug
});