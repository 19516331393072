<template>
  <v-card dark :width="width" class="primary app-menu-card">
    <v-card-text class="pa-10">
      <div class="text-left text-animated pb-4" @click="$emit('click')">
        <router-link to="/coachCertificate" class="white--text">{{$t('menu.home')}}</router-link>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
      <div class="text-left text-animated pb-4" @click="$emit('click')">
        <router-link to="/about" class="white--text">{{$t('menu.about')}}</router-link>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
      <div v-if="isAuthorizedAdmin()" class="text-left text-animated pb-4" @click="$emit('click')">
        <a :href="getAdminUrl()"  target="_blank" rel="noopener noreferrer" class="white--text">{{ $t('menu.coaches') }}</a>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
      <div v-authorize="{ path: '/chart'}"  class="text-left text-animated pb-4" @click="$emit('click')">
        <router-link to="/chart" class="white--text">{{$t('menu.chart')}}</router-link>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
      <div class="text-left text-animated" @click="$emit('click')">
        <a href="https://www.idrettsforbundet.no/digital/trenerattest/tilbakemelding/" target="_blank" rel="noopener noreferrer" class="white--text">{{ $t('menu.feedback') }}</a>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Authorize from '@/plugins/authorize';

export default {
  props: [ 'width' ],
  methods:{
    getAdminUrl(){
      return process.env.VUE_APP_MI_ACTIVECOACH_URL;
    },
    isAuthorizedAdmin(){
       return Authorize.isAuthorizedAdmin();
    }
  }
};

</script>

<style scoped>
@media (min-width: 600px) {
  .v-card__text {
    border: 2px solid white;
  }
}
</style>

