import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import Role from '@/plugins/role';
import authorize from '@/plugins/authorize';
import store from './store';
import '@/plugins/appinsights';
import moment from 'moment';
import "moment/locale/nb";

Vue.config.productionTip = false;

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('formatDateMonth', function(value) {
  if (value) {
      return moment(String(value)).format("LL");
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
      return moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
      return moment(String(value)).format('HH:mm:ss');
  }
});

Vue.use(VueCookies);
Vue.$cookies.config('1d','', domain_from_url(window.location.hostname), true, 'None');

new Vue({
  vuetify,
  i18n,
  router,
  Role,
  authorize,
  store,
  render: h => h(App)
}).$mount('#app');

function domain_from_url(url) {
  let result;
  let matchUrl = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im);
  if (matchUrl) {
     result = matchUrl[1];
     let match = result.match(/^[^.]+\.(.+..+)$/);
    if (match) {
         result = match[1];
     }
   }
  return result;
}