import Vue from 'vue';
import store from '@/store';
import router from '@/plugins/router';
import { Role } from '@/plugins/role';

Vue.directive('authorize', function (el, binding) {
   // Hide the element if the user is not authorized
   let route;
   router.options.routes.forEach(x => {
      if (x.children != undefined) {
         x.children.forEach(child => {
            if (child.path.toLowerCase() === binding.value.path.toLowerCase()) {
               route = child;
            }
         });
      }
   });
   const { authorize } = route.meta;
   const isAuthenticated = store.getters['oidcStore/oidcIsAuthenticated'];
   const orgtypes = route.meta.orgtypes;
   let orgTypeAllowed = true;
   if (orgtypes.length > 0) {
      const orgTypeId = store.getters['claims/orgTypeId'];
      orgTypeAllowed = orgtypes.includes(orgTypeId);
   }

   if (authorize) {
      if (!isAuthenticated) {
         el.hidden = true;
      }
      const currentUser = store.getters['oidcStore/oidcUser'];
      const functionTypes = currentUser.functiontypeids.split(',').map(x => Number(x));
      const functionTypeIds = [...new Set(functionTypes)];
      const authorizeIds = [...new Set(authorize[0])];
      const intersection = authorizeIds.filter(element => functionTypeIds.includes(element));

      // check if route is restricted by role
      if (authorize.length && intersection.length <= 0) {
         // role not authorised so hide element
         el.hidden = true;
      } else {
         //check if route is restricted by orgtypes
         if (!orgTypeAllowed && el.className.includes('v-tab')) {
            el.className = 'v-tab d-none';
         } else {
            el.className = el.className.replace('d-none', '');
         }
         el.hidden = false;
      }
   } else {
      el.hidden = true;
   }
});

export default new Vue({
   methods: {
      isAuthorizedAdmin: function () {
         const orgContexts = store.getters['claims/orgContexts'];
         const orgId = store.getters['claims/contextOrgId'];
         const authorizeIds = [...new Set(Role.Admin)];
         let orgs = orgContexts.filter(orgContext => orgContext.userContexts
            .some(userContext => authorizeIds
               .indexOf(userContext.functionTypeId) > -1)
         );
         const orgIds = [...new Set(orgs.map(x => x.orgId))];
         const intersection = orgIds.includes(orgId);
         // return role is authorised
         return intersection;
      }
   }
});